<template>
  <div>
    <el-row type="flex" class="top">
      <el-button type="primary" @click="dialogVisible=true">新建试卷</el-button>
      <el-button type="primary" @click="getPass(1)">刷新</el-button>
    </el-row>
    <el-row v-if="showHomeworkInfo===false">
      <el-table
          @row-click="row_clock"
          border
          ref="multipleTable"
          :data="tableData.records"
          tooltip-effect="dark"
          style="width: 100%">
        <el-table-column
            prop="title"
            label="作业名称"
        >
        </el-table-column>
        <el-table-column
            prop="username"
            label="创建者"
            width="100">
        </el-table-column>
        <el-table-column
            prop="userImg"
            label="创建者头像"
            width="120">
          <template slot-scope="scope">
            <el-avatar :src=scope.row.userImg></el-avatar>
          </template>
        </el-table-column>
        <el-table-column
            prop="number"
            label="提交数量"
            width="120">
          <template slot-scope="scope">
            {{ getNumber(scope.row) }}
          </template>
        </el-table-column>
        <el-table-column
            sortable
            prop="start"
            label="开始时间"
            width="200">
        </el-table-column>
        <el-table-column
            sortable
            prop="start"
            label="结束时间"
            width="200">
        </el-table-column>
        <el-table-column
            fixed="right"
            label="操作"
            width="100">
          <template slot-scope="scope">
            <el-button @click="deleteHomework(scope.row)" type="text">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
          v-if="tableData"
          background
          :current-page="tableData.current"
          :page-size="tableData.size"
          @current-change="getPass"
          layout="prev, pager, next"
          :total="tableData.total">
      </el-pagination>
    </el-row>

    <show-homework-info v-else :crowd-id="$route.params.id" :homework-id="homeworkId"></show-homework-info>

    <el-dialog
        title="提示"
        :visible.sync="dialogVisible"
        width="40%">
      <el-form ref="elForm" :model="temp" :rules="tempRules" size="medium" label-width="100px"
               label-position="left">
        <el-form-item label="作业名称" prop="title">
          <el-input v-model="temp.title" placeholder="请输入作业名称" clearable :style="{width: '100%'}"></el-input>
        </el-form-item>
        <el-form-item label="作业要求" prop="requirements">
          <el-input v-model="temp.requirements" type="textarea" placeholder="请输入作业要求"
                    :autosize="{minRows: 4, maxRows: 4}" :style="{width: '100%'}"></el-input>
        </el-form-item>
        <el-form-item label="作业类型" prop="type">
          <el-radio-group v-model="temp.type" size="medium">
            <el-radio v-for="(item, index) in typeOptions" :key="index" :label="item.value"
                      :disabled="item.disabled">{{ item.label }}
            </el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="作业时间" prop="start">
          <el-date-picker type="daterange"
                          v-model="temp.start"
                          format="yyyy-MM-dd HH:mm:ss"
                          value-format="yyyy-MM-dd HH:mm:ss"
                          :style="{width: '100%'}"
                          start-placeholder="开始日期"
                          end-placeholder="结束日期"
                          range-separator="至"
                          clearable></el-date-picker>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button @click="dialogVisible=false">取消</el-button>
        <el-button type="primary" @click="handelConfirm">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {addHomework, selectHomeworkVipByPid} from "@/api/homework";
import ShowHomeworkInfo from "@/views/crowd/homework/vip/show-homeworkInfo";

export default {
  name: "show-homework",
  components: {ShowHomeworkInfo},
  props: ["id"],
  data() {
    return {
      tableData: [],
      temp: {
        title: "",
        requirements: undefined,
        type: undefined,
        start: null,
      },
      tempRules: {
        title: [{
          required: true,
          message: '请输入作业名称',
          trigger: 'blur'
        }],
        requirements: [{
          required: true,
          message: '请输入作业要求',
          trigger: 'blur'
        }],
        type: [{
          required: true,
          message: '作业类型不能为空',
          trigger: 'change'
        }],
        start: [{
          required: true,
          message: '作业时间不能为空',
          trigger: 'change'
        }],
      },
      typeOptions: [{
        "label": "文字",
        "value": 1
      }, {
        "label": "文件",
        "value": 2
      }],
      dialogVisible: false,
      showHomeworkInfo: false,
      homeworkId: "",
      homeworkInfoData: []
    }
  },
  mounted() {
    this.getPass(1)
  },
  methods: {
    getPass(number) {
      selectHomeworkVipByPid(this.id, number).then(result => {
        this.tableData = result.data.homework;
      }).catch(error => {
        this.$message(error.message)
      })
    },
    handelConfirm() {
      this.$refs['elForm'].validate(valid => {
        if (!valid) return
        // this.close()
        console.log(this.temp);
        let homework = {
          pid: this.id,
          uid: this.$store.state.user.id,
          type: this.temp.type,
          start: this.temp.start[0],
          end: this.temp.start[1],
          title: this.temp.title,
          requirements: this.temp.requirements
        };
        addHomework(homework).then(result => {
          this.$message(result.data.homework);
          this.getPass(1);
        }).catch(error => [
          this.$message(error.message)
        ])
        this.dialogVisible = false;

      })
    },
    getNumber(item) {
      return item.number + "/" + item.total;
    },
    // 删除作业信息
    deleteHomework(row) {

    },
    row_clock(row, column, event) {
      // console.log("点击了某一行")
      console.log(row)
      // 显示一下作业信息
      this.showHomeworkInfo = true;
      this.homeworkId = row.id;
    }
  }
}
</script>

<style scoped>
.top{
  margin: 10px auto;
}
</style>
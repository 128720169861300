<template>
  <div>
    <el-row type="flex" class="top">
      <el-button  type="primary" @click="getPass(1)">刷新</el-button>
    </el-row>
    <el-row>
      <el-table
          border
          ref="multipleTable"
          :data="userTableData.records"
          tooltip-effect="dark"
          style="width: 100%">
        <el-table-column
            prop="title"
            label="作业名称"
            >
        </el-table-column>
        <el-table-column
            prop="username"
            label="创建者"
            width="100">
        </el-table-column>
        <el-table-column
            prop="userImg"
            label="创建者头像"
            width="120">
          <template slot-scope="scope">
            <el-avatar :src=scope.row.userImg></el-avatar>
          </template>
        </el-table-column>
        <el-table-column
            prop="number"
            label="提交数量"
            width="120">
          <template slot-scope="scope">
            {{ getNumber(scope.row) }}
          </template>
        </el-table-column>
        <el-table-column
            sortable
            prop="start"
            label="开始时间"
            width="200">
        </el-table-column>
        <el-table-column
            sortable
            prop="end"
            label="结束时间"
            width="200">
        </el-table-column>
        <el-table-column
            prop="isSubmit"
            label="你的状态"
            width="120">
          <template slot-scope="scope">
            <el-tag effect="dark" :type="scope.row.isSubmit===false ? 'danger' :'primary'">
              {{ getLev(scope.row.isSubmit) }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column
            fixed="right"
            label="操作"
            width="100">
          <template slot-scope="scope">
            <el-button @click="clickSubmit(scope.row)" type="text">提交作业</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
          v-if="userTableData"
          background
          :current-page="userTableData.current"
          :page-size="userTableData.size"
          @current-change="getPass"
          layout="prev, pager, next"
          :total="userTableData.total">
      </el-pagination>
    </el-row>
    <el-row>

    </el-row>
    <el-dialog
        title="提示"
        :visible.sync="uploadVisible"
        width="40%">
      <el-upload
          v-if="item.type===1"
          class="upload-demo"
          :action="action"
          :before-remove="beforeRemove"
          :on-success="handleAvatarSuccess"
          :limit="1"
          :on-exceed="handleExceed"
          :file-list="fileList">
        <el-button size="small" type="primary">点击上传</el-button>
        <div slot="tip" class="el-upload__tip">请上传压缩包,或者work等文档,只能上传单个文件</div>
      </el-upload>
      <div v-else>
        <el-input type="input"
                  v-model="url"
                  placeholder="请输入作业答案"
                  show-word-limit
                  clearable
                  :style="{width: '100%'}"></el-input>
      </div>
      <div slot="footer">
        <el-button @click="uploadVisible=false">取消</el-button>
        <el-button type="primary" @click="submitHomework">提交作业</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import Global from "@/config/global";
import {addHomeworkInfo, selectHomeworkByPid} from "@/api/homework";

export default {
  name: "show-homework-general",
  props: ["id"],
  data() {
    return {
      userTableData: [],
      action: Global.uploadUrl,
      uploadVisible: false,
      url: "",
      fileList: [],
      item: {},
      homeworkInfoId: "",

    }
  },
  mounted() {
    this.getPass(1);
  },
  methods: {
    // 获取首页信息
    getPass(number) {
      selectHomeworkByPid(this.id, number, this.$store.state.user.id).then(result => {
        this.userTableData = result.data.homework;
      }).catch(error => {
        this.$message(error.message)
      })
    },
    getLev(isSubmit) {
      if (isSubmit === true) {
        return "已经提交"
      } else {
        return "未提交"
      }
    },
    getNumber(item) {
      return item.number + "/" + item.total;
    },
    handleExceed(files, fileList) {
      this.$message.warning(`当前限制选择 1个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`);
    },
    beforeRemove(file, fileList) {
      return this.$confirm(`确定移除 ${file.name}？`);
    },
    // 点击提交作业按钮
    clickSubmit(item) {
      this.url = "";
      this.uploadVisible = true;
      this.item = item;
    },
    handleAvatarSuccess(res, file) {
      this.url = res.data.url;
      this.$message("上传成功,请点击提交");
    },
    // 提交作业
    submitHomework() {
      console.log(this.item)
      let homeworkInfo = {
        pid: this.item.id,
        uid: this.$store.state.user.id,
        url: this.url,
        type: this.item.type,
      }
      console.log(homeworkInfo)
      addHomeworkInfo(homeworkInfo).then(result => {
        this.$message(result.data.homework);
      }).catch(error => {
        this.$message(error.message);
      })
      this.uploadVisible = false;
    }
  }
}
</script>

<style scoped>
.top{
  margin: 10px auto;
}
</style>
<template>
  <div>
    <show-homework v-if="isManager" :id="id"></show-homework>
    <show-homework-general v-else :id="id"></show-homework-general>
  </div>
</template>

<script>

import ShowHomeworkGeneral from "@/views/crowd/homework/general/show-homework-general";
import ShowHomework from "@/views/crowd/homework/vip/show-homework";


export default {
  name: "homework",
  components: {ShowHomework, ShowHomeworkGeneral},
  props: {
    isManager: {
      type: Boolean,
      default() {
        return false
      }
    },
  },
  data() {
    return {
      id: this.$route.params.id,
    }
  },
  mounted() {

  },
  methods: {}
}
</script>

<style scoped>

</style>
import service from "@/utils/request";

/**
 * 添加作业信息
 * @returns {AxiosPromise}
 * @param homework
 */
export function addHomework(homework) {
    return service(
        {
            url: 'homework/addHomework',
            method: 'post',
            header: {
                "Content-Type": "application/json" //如果写成contentType会报错
            },
            data: homework
        })
}

/**
 * 群主查找作业信息
 * @param id
 * @param current
 * @returns {AxiosPromise}
 */
export function selectHomeworkVipByPid(id, current) {
    return service(
        {
            url: 'homework/selectHomeworkVipByPid',
            method: 'post',
            params: {
                id, current
            }
        })
}

/**
 *
 * @param id
 * @param current
 * @param uid
 * @returns {AxiosPromise}
 */
export function selectHomeworkByPid(id, current, uid) {
    return service(
        {
            url: 'homework/selectHomeworkByPid',
            method: 'post',
            params: {
                id, current, uid
            }
        })
}

/**
 * 完成作业
 * @param homeworkInfo
 * @returns {*}
 */
export function addHomeworkInfo(homeworkInfo) {
    return service(
        {
            url: 'homework/addHomeworkInfo',
            method: 'post',
            header: {
                "Content-Type": "application/json" //如果写成contentType会报错
            },
            data: homeworkInfo
        })
}

/**
 * 查找用户的所有信息
 * @param crowdId
 * @param homeworkId
 * @param current
 * @returns {AxiosPromise}
 */
export function selectHomeworkInfoByPid(crowdId, homeworkId, current) {
    return service(
        {
            url: 'homework/selectHomeworkInfoByPid',
            method: 'post',
            params: {
                crowdId, homeworkId, current
            }
        })
}
<template>
  <div class="info">
    <el-tabs v-model="activeName" type="card">
      <el-tab-pane label="已经提交" name="first">
        <el-table
            ref="multipleTable"
            :data="passData.records"
            tooltip-effect="dark"
            style="width: 100%">
          <!--          <el-table-column-->
          <!--              prop="title"-->
          <!--              label="作业名称"-->
          <!--          >-->
          <!--          </el-table-column>-->
          <el-table-column
              prop="username"
              label="提交者"
              width="100">
          </el-table-column>
          <el-table-column
              prop="userImg"
              label="创建者头像"
              width="120">
            <template slot-scope="scope">
              <el-avatar :src=scope.row.userImg></el-avatar>
            </template>
          </el-table-column>
          <el-table-column
              prop="gmtModified"
              label="提交时间">
          </el-table-column>
          <!--          <el-table-column-->
          <!--              fixed="right"-->
          <!--              label="操作"-->
          <!--              width="100">-->
          <!--            <template slot-scope="scope">-->
          <!--              <el-button @click="deleteHomework(scope.row)" type="text">删除</el-button>-->
          <!--            </template>-->
          <!--          </el-table-column>-->
        </el-table>
        <el-pagination
            v-if="passData"
            background
            :current-page="passData.current"
            :page-size="passData.size"
            @current-change="getPass"
            layout="prev, pager, next"
            :total="passData.total">
        </el-pagination>
      </el-tab-pane>
      <el-tab-pane label="未提交" name="second">
        <el-table
            border
            ref="noPassData"
            :data="noPassData.records"
            tooltip-effect="dark"
            style="width: 100%">
          <!--          <el-table-column-->
          <!--              prop="title"-->
          <!--              label="作业名称"-->
          <!--          >-->
          <!--          </el-table-column>-->
          <el-table-column
              prop="username"
              label="创建者"
              width="100">
          </el-table-column>
          <el-table-column
              prop="userImg"
              label="创建者头像"
              width="120">
            <template slot-scope="scope">
              <el-avatar :src=scope.row.userImg></el-avatar>
            </template>
          </el-table-column>
          <!--          <el-table-column-->
          <!--              sortable-->
          <!--              prop="start"-->
          <!--              label="开始时间"-->
          <!--              width="200">-->
          <!--          </el-table-column>-->
          <!--          <el-table-column-->
          <!--              fixed="right"-->
          <!--              label="操作"-->
          <!--              width="100">-->
          <!--            <template slot-scope="scope">-->
          <!--              <el-button @click="deleteHomework(scope.row)" type="text">删除</el-button>-->
          <!--            </template>-->
          <!--          </el-table-column>-->
        </el-table>
        <el-pagination
            v-if="noPassData"
            background
            :current-page="noPassData.current"
            :page-size="noPassData.size"
            @current-change="getPass"
            layout="prev, pager, next"
            :total="noPassData.total">
        </el-pagination>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import {selectHomeworkInfoByPid} from "@/api/homework";
// 查看作业详情
export default {
  name: "show-homeworkInfo",
  props: ["crowdId", "homeworkId"],
  data() {
    return {
      passData: [],
      noPassData: [],
      activeName: 'first'
    }
  },
  mounted() {
    this.getPass(1)
  },
  methods: {
    getPass(number) {
      selectHomeworkInfoByPid(this.crowdId, this.homeworkId, number).then(result => {
        console.log(result.data)
        this.passData = result.data.pass;
        this.noPassData = result.data.noPass;
      }).catch(error => {
        this.$message(error.message)
      })
    }
  }
}
</script>

<style scoped>
.info {
  height: 100%;
}
</style>